<template>
  <div class="background-validate">
    <div>
      <InputOTP
        @submitValidateOTP="submitValidateOTP"
        :type="1"
        @clickChangePhone="clickChangePhone"
      />
    </div>
    <ModalError ref="modalError" :text="successMessage" />
  </div>
</template>

<script>
import ModalError from "@/components/alert-modal/ModalError";
import InputOTP from "@/components/input/InputOTP";
import { required, minLength, integer } from "vuelidate/lib/validators";
export default {
  name: "confirmotp",
  components: {
    ModalError,
    InputOTP
  },
  data() {
    return {
      successMessage: ""
    };
  },
  methods: {
    submitValidateOTP: async function (val) {
      this.isLoading = true;
      await this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/VerifyOTP`, val)
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.$store.commit("setEditPhoneNumber", false);
            if (data.detail.is_new_user == 1) {
              //new User
              if (this.$route.query.redirect)
                return this.$router.replace({
                  path: "register",
                  query: {
                    tel: val.Telephone,
                    ...this.$route.query
                  }
                });
              this.$router.replace({
                path: "register",
                query: { tel: val.Telephone }
              });
              // this.$router.replace("/register");
            } else {
              // Old User
              this.$cookies.set("hourglass_token", data.detail.token);

              if (this.$route.query)
                return this.$router.replace({
                  path: "review",
                  query: { ...this.$route.query }
                });
              this.$router.replace("/review");
            }
          } else {
            this.successMessage = "Invalid OTP code";
            this.$refs.modalError.show();
          }
        });
    },
    clickChangePhone(val) {
      var path = "";
      if (this.$route.query.redirect) {
        path = "/validatetelephone?redirect=" + this.$route.query.redirect;
      } else {
        path = "/validatetelephone";
      }
      this.$router.push(path);
    }
  },
  validations() {
    return {
      form: {
        OTP: {
          minLength: minLength(6),
          integer,
          required
        }
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.input-number {
  align-items: center;
  justify-content: center;
}
.bg-trans {
  background-color: transparent;
}
.background-validate {
  background-color: #fff;
  // position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>
